<template>
  <main class="grow">
    <section>
      <div class="relative max-w-5xl mx-auto px-4 sm:px-6">
        <div class="pt-4 pb-16 md:pt-8 md:pb-12">
          <section>
            <div
              class="w-full min-h-84 rounded-3xl lg:flex block lg:p-12 p-3 mx-auto bg-[url('@/assets/images/stellar/talk-to-us-bg.svg')] bg-cover"
            >
              <!-- Content -->
              <div
                class="lg:max-w-3xl max-w-full mx-auto text-center lg:px-0 px-3 lg:py-0 py-6 lg:border-none border border-[#55DAF7D1] lg:rounded-none rounded-xl"
              >
                <h2
                  class="lg:text-3xl/snug md:text-2xl/normal text-lg font-bold bg-clip-text capitalize text-transparent bg-gradient-to-r from-slate-50/65 via-slate-50 to-slate-50/65 mb-8"
                >
                  {{ $t("subscribe_title") }}
                </h2>
                <p class="text-lg/7 text-[#d6d6d6] mb-8">
                  {{ $t("subscribe_subtitle") }}
                </p>

                <Vueform
                  v-model="formData"
                  validate-on="change|step"
                  class="vueFormContainer"
                  :endpoint="false"
                  ref="subscribeFormRef"
                  @submit="submitSubscribeNewsletter"
                  :display-messages="false"
                  :display-errors="false"
                >
                  <TextElement
                    name="email"
                    input-type="email"
                    :placeholder="$t('subscribe_email')"
                    rules="required|email"
                    :field-name="$t('email')"
                    class=""
                  />
                  <StaticElement name="subscribe_text">
                    <p class="text-sm/7 text-grey-100 mb-6 ">
                      {{ $t("subscribe_text") }}
                    </p>
                  </StaticElement>

                  <ButtonElement
                    name="button"
                    :submits="true"
                    :loading="is_loading"
                    button-class="min-w-32 w-auto btn-sm h-9 py-0.5 text-slate-300 hover:text-white hover:no-underline transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.500),_theme(colors.slate.500))_padding-box,_linear-gradient(theme(colors.cyan.500),_theme(colors.cyan.200)_75%,_theme(colors.transparent)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow "
                    :add-classes="{
                      ElementLayout:{
                        innerWrapper:['max-w-32 w-auto mx-auto inline-flex relative before:absolute before:inset-0 before:bg-cyan-500 before:blur-md mb-2']
                      }
                    }"
                    :replace-classes="{
                      ButtonElement:{
                        button_md:{'form-radius-btn':'rounded-full'}
                      }
                    }"
                  >
                    <template #default>
                      <span class="relative inline-flex items-center">
                        {{ $t("subscribe_btn") }}
                        <span
                          class="tracking-normal text-[#55EDF7] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"
                          ><font-awesome-icon icon="fa-solid fa-arrow-right"
                        /></span>
                      </span>
                    </template>
                  </ButtonElement>
                </Vueform>
                <!-- <div
                  class="relative bg-gradient-to-br from-[#55DAF7] via-[#99D5FF] to-black/0 rounded-full shadow-lg p-0.5 h-10 w-auto max-w-fit mx-auto mb-5"
                >
                  <a
                    class="btn rounded-full h-full w-full text-slate-300 text-base/normal font-medium bg-slate-800 transition duration-150 ease-in-out group"
                    href="/early-invite"
                  >
                    {{ $t("subscribe_btn") }}
                    <span
                      class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2 my-auto"
                      ><font-awesome-icon icon="fa-solid fa-arrow-right" />
                    </span>
                  </a>
                </div> -->
                <!-- <div
                  class="mt-10 inline-flex relative before:absolute before:inset-0 before:bg-cyan-500 before:blur-md mb-2"
                >
                  <button
                    class="min-w-28 w-auto btn-sm h-9 py-0.5 text-slate-300 hover:text-white hover:no-underline transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.500),_theme(colors.slate.500))_padding-box,_linear-gradient(theme(colors.cyan.500),_theme(colors.cyan.200)_75%,_theme(colors.transparent)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                  >
                    <span class="relative inline-flex items-center">
                      {{ $t("subscribe_btn") }}
                      <span
                        class="tracking-normal text-[#55EDF7] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"
                        ><font-awesome-icon icon="fa-solid fa-arrow-right"
                      /></span>
                    </span>
                  </button>
                </div> -->
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import TalkToUsHero from "@/partials/TalkToUsHero.vue";

import axios from "axios";

export default {
  name: "SubscribeNewsletter",
  components: {},
  data() {
    return {
      formData: null,

      is_loading: false,
    };
  },
  mounted() {},
  methods: {
    submitTalkToUs(e) {
      console.log("in submit talk to us");
      console.log(this.formData);
      console.log(this.$refs.talkToUsFormRef);
      // e.preventDefault()
      this.is_loading = true;

      this.formData.lead_form = "Early Adopter Waitlist";

      // return;
      axios
        .post("early-program/register-client", this.formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            client: "PROTOSTARS",
          },
        })
        .then((response) => {
          console.log(response);
          this.$swal({
            // title: "Title",
            text: this.$t("talk_to_us_success_message"),
            icon: "success",
            confirmButtonColor: "#55B3F7",
            customClass: {
              confirmButton:
                "bg-gray-100  rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal ",
            },
            color: "#F3F4F6",

            background: "#374151",
            // timer: 3000,
          }).then((r) => {
            // console.log(r.value);
            // this.formData = {}
            this.$refs.talkToUsFormRef.reset();
            this.is_loading = false;
            this.$router.push("/");
          });
        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            // title: "Title",
            text: error.response.data.error
              ? error.response.data.error
              : error.response.data.message,
            icon: "error",
            confirmButtonColor: "#03989E",
            customClass: {
              confirmButton: "btn btn-primary ",
            },
            // timer: 3000,
          }).then((r) => {
            // console.log(r.value);
            this.is_loading = false;
          });
        });
    },
  },
};
</script>

<style></style>
