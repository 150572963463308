<template>

    <!-- <v-select :class="'form-control '+select_language_class" :options="languages_list" v-model="$i18n.locale" label="name"
      :reduce="(item) => item.val" :clearable="false" :multiple="false" :searchable="false" @input="changeLanguage">
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><font-awesome-icon icon="fa-solid fa-chevron-down" /></span>
      </template>
</v-select> -->

    <!-- <select v-model="$i18n.locale">
      <option v-for="(lang, i) in languages_list" :key="`Lang${i}`" :value="lang.val">
        {{ lang.name }}
      </option>
    </select> -->
    <!-- {{ $i18n }} -->

    <v-select class="select-language mr-2" :options="languages_list" v-model="$i18n.locale" label="name" :reduce="(item) => item.val"
      :clearable="false" :multiple="false" :searchable="false" @input="changeLanguage">
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><font-awesome-icon icon="fa-solid fa-chevron-down" /></span>
      </template>
    </v-select>
</template>

<script>
import { ref } from 'vue'

export default {
  name: "LanguageComponent",
  props: ['form_group_class', 'select_language_class'],
  data() {
    return {
      languages_list: [

        {
          "name": "English",
          "val": "en"
        },
        {
          "name": "Français",
          "val": "fr"
        },
        {
          "name": "Español",
          "val": "es"
        }
      ]
    }
  },
  mounted() {
    // this.$refs.myVSelect.open = true
  },
  // watched:{
  //   $i18n.locale(newval){

  //   }    
  // },
  
  watch: {
    '$i18n.locale'(newval) {
      // console.log("watch lang", newval)
      this.$i18n.locale = newval
      localStorage.setItem('language', newval)
    },
  },

  methods: {
    changeLanguage(obj) {
      // console.log("change lang",obj)
      // console.log(this.$i18n)
      this.$i18n.global.locale = obj
      // localStorage.setItem('language', obj)
      localStorage.setItem('items', "testtest"); 

    }
  }
}
</script>