<template>
  <header class="absolute w-full z-1000">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-16 md:h-20">

        <!-- Site branding -->
        <div class="flex-1">
          <!-- Logo -->
          <router-link class="inline-flex " to="/" aria-label="protostars" >
            <img class="md:max-w-none max-w-[150px]" src="@/assets/images/logo-light.png" width="200" height="30"
              alt="Protostars">
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="hidden lg:flex lg:grow">

          <!-- Desktop menu links -->
          <ul class="flex grow justify-center flex-wrap items-center">
            <!-- <li>
              <router-link
                class="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                to="/">{{ $t('header_about') }}</router-link>
            </li> -->
            <li>
              <router-link
                class="font-medium text-base/relaxed text-slate-300 hover:text-white mx-4 xl:mx-5 transition duration-150 ease-in-out"
                to="/#how-it-works">{{ $t('header_how_it_works') }}</router-link>
            </li>
            <li>
              <router-link
                class="font-medium text-base/relaxed text-slate-300 hover:text-white mx-4 xl:mx-5 transition duration-150 ease-in-out"
                to="/#integrations">{{ $t('header_integrations') }}</router-link>
            </li>
            <li>
                <router-link class="font-medium text-base/relaxed text-slate-300 hover:text-white mx-4 xl:mx-5 transition duration-150 ease-in-out" 
                  to="/cyber-grants">{{
                  $t('header_cyber_grants_btn') }}</router-link>
              </li>
            <!-- <li>
                <router-link class="font-medium text-base/relaxed text-slate-300 hover:text-white mx-4 xl:mx-5 transition duration-150 ease-in-out" 
                  to="/blog">{{ $t('header_blog_btn') }}</router-link>
            </li> -->
            <!-- <li>
              <router-link
                class="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                to="/#pricing">{{$t('header_pricing')}}</router-link>
            </li> -->
            <!-- <li>
              <router-link
                class="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                to="/#clients">{{ $t('header_customers') }}</router-link>
            </li> -->
            <!-- <li>
              <router-link
                class="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                to="/#contactus">{{ $t('header_contact') }}</router-link>
            </li> -->
            <!-- <li>
              <router-link
                class="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                to="/">{{ $t('header_blog') }}</router-link>
            </li> -->
          </ul>

        </nav>

        <!-- Desktop sign in links -->
        <ul class="hidden md:flex-1 md:flex justify-end items-center">
          <li>
            <LanguageComponent />
          </li>
          <!-- <li>
            <a class="font-medium text-sm text-slate-300 hover:text-white whitespace-nowrap transition duration-150 ease-in-out"
              href="https://platform.protostars.ai/protostars/login" target="_blank">{{ $t('header_sign_in') }}</a>
          </li> -->
          <!-- <li class="ml-6">
            <a class="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
              href="https://platform.protostars.ai/register" target="_blank">
              <span class="relative inline-flex items-center">
                {{ $t('header_sign_up') }} <span
                  class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                    icon="fa-solid fa-arrow-right" /></span>
              </span>
            </a>
          </li> -->
          <!-- <li class="ml-6">
            <a class="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
              href="/early-invite">
              <span class="relative inline-flex items-center">
                {{ $t('early_wishlist_title') }} <span
                  class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                    icon="fa-solid fa-arrow-right" /></span>
              </span>
            </a>
          </li> -->
          <li class="ml-6">
            <a class="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
              href="https://platform.protostars.ai/protostars/login" target="_blank">
              <span class="relative inline-flex items-center">
                {{ $t('header_sign_in') }} <span
                  class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                    icon="fa-solid fa-arrow-right" /></span>
              </span>
            </a>
          </li>
        </ul>

        <!-- Mobile menu -->
        <div class="lg:hidden flex items-center ml-4">

          <!-- Hamburger button -->
          <button class="hamburger" ref="hamburger" :class="{ 'active': mobileNavOpen }"
            @click="mobileNavOpen = !mobileNavOpen" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen">
            <span class="sr-only">Menu</span>
            <svg class="w-5 h-5 fill-current text-slate-300 hover:text-white transition duration-150 ease-in-out"
              viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect y="2" width="20" height="2" rx="1" />
              <rect y="9" width="20" height="2" rx="1" />
              <rect y="16" width="20" height="2" rx="1" />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <nav id="mobile-nav"
            class="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
            ref="mobileNav"
            :style="[mobileNavOpen ? { maxHeight: $refs.mobileNav.scrollHeight + 'px', opacity: 1 } : { maxHeight: 0, opacity: .8 }]"
            @click="expanded = false" @keydown.escape="expanded = false">
            <ul
              class="border border-transparent [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] rounded-lg px-4 py-1.5">
              <li class="md:max-lg:hidden">
                <ul class="flex-1 flex justify-center items-center">
                  <li>
                    <LanguageComponent />
                  </li>
                  <!-- <li>
                    <a class="font-medium text-sm text-slate-300 hover:text-white whitespace-nowrap transition duration-150 ease-in-out"
                      href="https://platform.protostars.ai/protostars/login" target="_blank">{{ $t('header_sign_in')
                      }}</a>
                  </li> -->
                  <!-- <li class="ml-6">
                    <a class="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
                      href="https://platform.protostars.ai/register" target="_blank">
                      <span class="relative inline-flex items-center">
                        {{ $t('header_sign_up') }} <span
                          class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                            icon="fa-solid fa-arrow-right" /></span>
                      </span>
                    </a>
                  </li> -->
                  <!-- <li class="ml-6">
                    <a class="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
                      href="/early-invite">
                      <span class="relative inline-flex items-center">
                        {{ $t('early_wishlist_title') }} <span
                          class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                            icon="fa-solid fa-arrow-right" /></span>
                      </span>
                    </a>
                  </li> -->
                  <li class="ml-6">
                    <a class="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
                      href="https://platform.protostars.ai/protostars/login">
                      <span class="relative inline-flex items-center">
                        {{ $t('header_sign_in') }} <span
                          class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                            icon="fa-solid fa-arrow-right" /></span>
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <router-link class="flex font-medium text-sm text-slate-300 hover:text-white py-1.5" to="/#how-it-works">{{
                  $t('header_how_it_works') }}</router-link>
              </li>
              <li>
                <router-link class="flex font-medium text-sm text-slate-300 hover:text-white py-1.5" to="/#integrations">{{
                  $t('header_integrations') }}</router-link>
              </li>
              <li>
                <router-link class="flex font-medium text-sm text-slate-300 hover:text-white py-1.5" to="/cyber-grants">{{
                  $t('header_cyber_grants_btn') }}</router-link>
              </li>
               <!-- <li>
                <router-link class="flex font-medium text-sm text-slate-300 hover:text-white py-1.5" 
                  to="/blog">{{
                  $t('header_blog_btn') }}</router-link>
              </li> -->
              <!-- <li>
                <router-link class="flex font-medium text-sm text-slate-300 hover:text-white py-1.5"
                  to="/#pricing">{{$t('header_pricing')}}</router-link>
              </li> -->
              <!-- <li>
                <router-link class="flex font-medium text-sm text-slate-300 hover:text-white py-1.5" to="/#clients">{{
                  $t('header_customers') }}</router-link>
              </li> -->
              <!-- <li>
                <router-link class="flex font-medium text-sm text-slate-300 hover:text-white py-1.5" to="/">{{
                  $t('header_blog') }}</router-link>
              </li> -->
            </ul>
          </nav>

        </div>

      </div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import LanguageComponent from '@/components/language/Language.vue'

export default {
  name: 'HeaderSection',
  components: { LanguageComponent },
  setup() {

    const mobileNavOpen = ref(false)
    const mobileNav = ref(null)
    const hamburger = ref(null)

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen.value || mobileNav.value.contains(target) || hamburger.value.contains(target)) return
      mobileNavOpen.value = false
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen.value || keyCode !== 27) return
      mobileNavOpen.value = false
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      mobileNavOpen,
      mobileNav,
      hamburger,
    }
  }
}
</script>
